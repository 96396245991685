import React, {Component} from 'react';


import 'font-awesome/css/font-awesome.min.css';

import "react-datepicker/dist/react-datepicker.css";

import About from '../CustomComponents/About/About'
import Terms from '../CustomComponents/Terms/Terms'
import Privacy from '../CustomComponents/Privacy/Privacy'
import Support from '../CustomComponents/Support/Support'
import CustomLogin from '../CustomComponents/Login/CustomLogin'
import ForgetPass from '../CustomComponents/ForgetPass/ForgetPass'
// import CustomSignup from '../CustomComponents/Signup/CustomSignup'
import ConfirmEmail from '../CustomComponents/ConfirmEmail/ConfirmEmail'
// eslint-disable-next-line no-unused-vars
import MainPage from '../CustomComponents/MainPage/MainPage'
import Help from '../CustomComponents/Help/Help'
import Clients from '../CustomComponents/Clients/Clients'
import HistoryPage from '../CustomComponents/History/History'
import Transporters from '../CustomComponents/Transporters/Transporters'
import Trucks from '../CustomComponents/Trucks/Trucks'
import Dispatchers from '../CustomComponents/Dispatchers/Dispatchers';
import Loads from '../CustomComponents/Loads/Loads'
import Load from '../CustomComponents/Load/Load'
import UpdateLoad from '../CustomComponents/UpdateLoad/Load'
import Settings from '../CustomComponents/Settings/Settings'
import ViewLoad from '../CustomComponents/ViewLoad/Load'

import { Route, Switch, Redirect } from 'react-router-dom';


const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props => (auth.authenticate() ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/login',
          state: {from: props.location},
        }}
      />
    ))
    }
  />
);

const auth = {

  isAuthenticate: false,
  authenticate() {
    window.screenY = 0;

    if (JSON.parse(localStorage.getItem('userInfo'))) {
      this.isAuthenticate = true;
    }

    return this.isAuthenticate;
  },
};

class mainbuilder extends Component {
  render() {
    return (
      <Switch>

        <Route path="/forgetPass" component={ForgetPass}/>
        <Route path="/login" component={CustomLogin}/>
        <Route path="/login/" component={CustomLogin}/>
        <Route path="/support" component={Support}/>
        <Route path="/about" component={About}/>
        <Route path="/terms-and-conditions" component={Terms}/>
        <Route path="/privacy" component={Privacy}/>
        {/* <Route path="/signup" component={CustomSignup}/> */}
        <Route path="/confirmEmail" component={ConfirmEmail}/>
        <PrivateRoute exact path="/dashboard" component={MainPage}/>
        <PrivateRoute exact path="/help" component={Help}/>
        <PrivateRoute exact path="/clients" component={Clients}/>
        <PrivateRoute exact path="/transporters" component={Transporters}/>
        <PrivateRoute exact path="/trucks" component={Trucks}/>
        <PrivateRoute exact path="/dispatchers" component={Dispatchers}/>
        <PrivateRoute exact path="/history" component={HistoryPage}/>
        <PrivateRoute path="/loads" component={Loads}/>
        <PrivateRoute path="/load/:id/view" component={ViewLoad}/>
        <PrivateRoute path="/load/:id/client" component={Load}/>
        <PrivateRoute path="/load/:id" component={UpdateLoad}/>
        <PrivateRoute path="/load" component={Load}/>
        <PrivateRoute exact path="/settings" component={Settings}/>
        <Route path="/" component={CustomLogin}/>

      </Switch>
    );
  }
}

export default mainbuilder;
