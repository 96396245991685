import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/action';
import {Link} from 'react-router-dom';
import './style.css'
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button} from "reactstrap";
import UsersAPI from '../../../api/userApi'

class CustomLogin extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rememberMe: false,
      error: false
    };
  }

  componentDidMount() {
    const {history,} = this.props;

    try {
      const authKey = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';
    if (authKey.length) {
      history.push('/dashboard');
    }
    } catch (e) {
      console.log('error', e)
    }
  }

  handleSubmit = async (event, errors, values) => {
    const {email, password} = values;
    const {rememberMe} = this.state;
    const {history,} = this.props;

    if(errors.length === 0){
      const res = await UsersAPI.SignIn(email, password, rememberMe)

      if (res.status === 200 ) {
        localStorage.setItem('userInfo', JSON.stringify(res.data.user));
        localStorage.removeItem('dashboardData');
        history.push('/dashboard');
      } else {
        const extractedMessage = res && res.message && (res.message.includes('///') ? res.message.split('///')[0].trim() : res.message.trim());
        this.setState({error: true, errorMessage: extractedMessage})
      }

    }
  }

  rememberMe = () => {
    this.setState({rememberMe: !this.state.rememberMe})
  }


  render() {

    const {error, errorMessage} = this.state
    const {location} = this.props

    return (
      <AUX>
        <div className="wrapper-page">
          <div className="custom-login card overflow-hidden account-card mx-3">

              <div className="custom-login-header">
                {/* <div className="logo logo-admin"> */}
                  <img className="logo logo-admin" src="assets/images/logo-full.png" alt="logo"/>
                {/* </div> */}
                <h4>Login</h4>
                <p>Welcome Back!</p>
              </div>

              {location.search.indexOf('true') > 0 &&(<div className="custom-login-msg">Your email is confirmed</div>)}

              <div className="account-card-content">

                <AvForm onSubmit={this.handleSubmit}>
                  <AvField
                    name="email"
                    label="Email"
                    type="email"
                    errorMessage="Invalid Email"
                    maxLength="100"
                    placeholder={'Enter your email'}
                    validate={{required: {value: true}, email: {value: true}}}/>
                  <AvField
                    name="password"
                    label="Password"
                    maxLength="100"
                    type="password"
                    errorMessage="Enter password"
                    placeholder={'Enter password'}
                    validate={{required: {value: true}}}/>

                  {error ? (<span className='errorMsg'>{errorMessage}</span>) : null}
                  <div className="form-group row m-t-20">
                    <div className="col-sm-6">
                      {/*<div className="custom-control custom-checkbox" onClick={this.rememberMe}>*/}
                        {/*<input type="checkbox" className="custom-control-input" id="customControlInline"/>*/}
                        {/*<label className="custom-control-label" htmlFor="customControlInline">Remember me</label>*/}
                      {/*</div>*/}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button className="btn btn-primary w-md custom-btn" type="submit">Log In</Button>
                    </div>
                  </div>

                  <div className="form-group m-t-10 mb-0 row">
                    <div className="col-12 m-t-20" style={{textAlign: 'center'}}>
                      <Link to="/forgetPass">Forgot your password?</Link>
                    </div>
                  </div>
                </AvForm>
              </div>

          </div>

          {/* <div className="m-t-40 text-center custom-login-text">
            <p>Don't have an account? <Link to="/signup" className="font-500"> Register now </Link> </p>
          </div> */}
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateLogin: () => dispatch({type: actionTypes.LOGINPAGE, value: true}),
    UpdateLoginAgain: () => dispatch({type: actionTypes.LOGINPAGE, value: false})
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(CustomLogin);
