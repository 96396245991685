
const carTypes = [
  {value: 'hatchback', label: 'Hatchback'},
  {value: 'sedan', label: 'Sedan'},
  {value: 'mpv', label: 'MPV'},
  {value: 'suv', label: 'SUV'},
  {value: 'van', label: 'Van'},
  {value: 'pickup', label: 'Pickup'},
  {value: 'motorcycle', label: 'Motorcycle'},
  {value: 'coupe', label: 'Coupe'},
  {value: 'convertible', label: 'Convertible'},
  {value: 'wagon', label: 'Wagon'},
  {value: 'truck', label: 'Truck'},
  {value: 'bus', label: 'Bus'}
];

const encloseTrailerMap = {
  10: 'Dry van',
  20: 'Reefer',
  0: 'Flatbed',
};



export function getEncloseValue(trailerType) {
  return encloseTrailerMap[trailerType] || null; // Returns the mapped value or null if the type is not found
}



export default carTypes
