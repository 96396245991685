import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/action';
import './style.css'
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button} from "reactstrap";
import SupportAPI from '../../../api/supportAPI'

class Support extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rememberMe: false,
      error: false
    };
  }

  componentDidMount() {
    // const {history,} = this.props;
    // const authKey = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).auth_key : '';
    // if (authKey.length) {
    //   history.push('/dashboard');
    // }
  }

  handleSubmit = async (event, errors, values) => {
    const {email, phone, category, question} = values;
    const {history,} = this.props;

    if(errors.length === 0) {
      const res = await SupportAPI.createTicket({email, phone, category, question});

      if (res.status === 200 ) {
        localStorage.setItem('userInfo', JSON.stringify(res.data.user));
        localStorage.removeItem('dashboardData');
        this.setState({error: false, errorMessage: '', success: true})
      } else {
        const extractedMessage = res && res.message && (res.message.includes('///') ? res.message.split('///')[0].trim() : res.message.trim());
        this.setState({error: true, errorMessage: extractedMessage, success: true})
      }

    }
  }

  rememberMe = () => {
    this.setState({rememberMe: !this.state.rememberMe})
  }


  render() {

    const {error, errorMessage, success} = this.state
    const {location} = this.props

    return (
      <AUX>
        <div className="wrapper-page">
          <div className="custom-login card overflow-hidden account-card mx-3">

              <div className="custom-login-header">
                <img className="logo logo-admin" src="assets/images/logo-full.png" alt="logo"/>
                <h4>Support</h4>
                <p>How Can We Help?</p>
              </div>

              {success && (<div className="custom-login-msg">Your request ticket has been created.</div>)}

              <div className="account-card-content">

                <AvForm onSubmit={this.handleSubmit}>
                  <AvField
                    name="email"
                    label="Email"
                    type="email"
                    errorMessage="Invalid Email"
                    maxLength="100"
                    placeholder={'Enter your email'}
                    validate={{required: {value: true}, email: {value: true}}}/>

                  <AvField
                    name="phone"
                    label="Phone"
                    type="phone"
                    errorMessage="Invalid Phone"
                    maxLength="100"
                    placeholder={'Enter your phone'}
                    validate={{required: {value: false}, phone: {value: true}}}/>
                  
                  <AvField
                    type="select"
                    name="category"
                    label='Request Type'
                    className="load-select-drivers"
                    defaultValue="Request User Account Delete" // Set default value here
                    onChange={(e) => this.setState({selectedCategory: e.target.value})}>
                      <option key={'general_support'} value="General Support Request">{'Ask any support question'}</option>
                      <option key={'user_accout_delete'} value="Request User Account Delete" >{'Request user account delete and all related data'}</option>
                      <option key={'mobile_app_issue'} value="Mobile Application Issue">{'Mobile application issue'}</option>
                      <option key={'report_bug'} value="Report a Bug">{'Report a bug'}</option>
                      <option key={'feature_request'} value="Feature Request">{'Request a feature'}</option>
                      <option key={'other'} value="Other">{'Other'}</option>
                  </AvField>

                  <AvField
                    name="question"
                    label="Question"
                    errorMessage="Missing question"
                    type="textarea"
                    rows="6"
                    value={this.state.note || ''}
                    validate={{required: {value: true}}}
                    maxLength={"4000"}
                  />

                  {error ? (<span className='errorMsg'>{errorMessage}</span>) : null}
                  <div className="form-group row m-t-20">
                    <div className="col-sm-6"></div>
                    <div className="col-sm-6 text-right">
                      <Button className="btn btn-primary w-md custom-btn" type="submit">SEND</Button>
                    </div>
                  </div>
                </AvForm>
              </div>

          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {
    loginpage: state.ui_red.loginpage
  };
}

const mapDispatchtoProps = dispatch => {
  return {
    UpdateLogin: () => dispatch({type: actionTypes.LOGINPAGE, value: true}),
    UpdateLoginAgain: () => dispatch({type: actionTypes.LOGINPAGE, value: false})
  };
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Support);
