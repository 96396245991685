import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
// import uuidv4 from "uuid";
import './style.css'
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import LoadAPI from "../../../api/loadsAPI";
import CarAPI from "../../../api/carsAPI";
import {AvField, AvForm} from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import TransportersAPI from "../../../api/transportersAPI";
import {convertUTCToLocal} from "../../../helpers/DateUtils";
import {getEncloseValue} from "../../../utils/carTypes";

class Load extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loadData: {},
      cars: [],
      carsForm: false,
      error: false,
      carsFields: {
        makerOption: '',
        modelOption: '',
        vin: '',
        mileage: '',
        year: '',
        keys: '',
        type: '',
        inop: 'off',
        enclose: 'off',
      },
      carsSelectOptions: [],
      modelsSelectOptions: [],
      pick_up_date: '',
      delivery_date: '',
      myDriver: '',
      subscriptionStatus: '',
    }
  }

  async componentDidMount() {
    const {match} = this.props;
    const loadData = await LoadAPI.GetLoad(match.params.id);
    const loadCarsData = await LoadAPI.GetLoadsCars(match.params.id);
    const myDrivers = await TransportersAPI.GetTransportersData()
    const carsData = await CarAPI.GetCarsData();

    const carsSelectOptions = carsData.data.data.map((item) => {
      return {value: item.title, label: item.title, id: item.id}
    })

    let myDriver

    if (loadData.data.data.driver_data) {
      myDriver = `${loadData.data.data.driver_data.first_name} ${loadData.data.data.driver_data.last_name}`
    }

    else if(loadData.data.data.driver_id){
      const data = myDrivers.data.data.filter((item) => {
        return loadData.data.data.driver_id === item.id
      })
      myDriver = data[0].first_name
    }

    else {
      myDriver = '-'
    }

    this.setState({
      loadData: loadData.data.data,
      cars: loadCarsData.data.data,
      carsSelectOptions,
      myDriver,
      selectedDriver: loadData.data.data.driver_data !== null ? JSON.stringify(loadData.data.data.driver_data) : false,
    })
  }

  submitLoad = async (event, errors, values) => {

    const {history} = this.props;
    const {cars, loadData, pick_up_date, delivery_date} = this.state;

    if (errors.length === 0) {
      await LoadAPI.UpdateLoad({
        load: {
          id: loadData.id,
          load_id: values.loadNumber,
          pick_up_date: pick_up_date,
          delivery_date: delivery_date,
          driver_id: values.driverId,
          hauling: values.driverPrice
        },
        origin: {
          name: values.origin,
          address: values.originAddress,
          email: values.originEmail,
          phone: values.originPhone,
        },
        destination: {
          name: values.destination,
          address: values.destinationAddress,
          email: values.destinationEmail,
          phone: values.destinationPhone,
        }
      }).then((res) => {

        const promises = cars.map((item) => {

          return (
            new Promise((resolve, reject) => {
              CarAPI.CreateCars({
                load_id: loadData.id,
                car_maker_id: item.makerOption.id,
                car_model_id: item.modelOption.id,
                vin: item.vin,
                year: item.year,
                mileage: item.mileage,
                keys: item.keys,
              })
            })
          )
        })

        Promise.all(promises)
        history.push('/loads');

      }).catch((error) => {
        console.log('ERROR', error)
        this.setState({error: true})

      })
    }
  }

  showCarsForm = () => this.setState({carsForm: !this.state.carsForm})

  createCar = () => {
    const {cars, carsFields} = this.state;
    cars.push(carsFields)
    this.setState({cars})
  }

  deleteCar = async (id, index) => {
    const {cars} = this.state;
    if (id) {
      await CarAPI.DeleteCars(id)
    }

    const carsArray = cars.filter((item, i) => index !== i ? item : null)

    this.setState({cars: carsArray})
  }

  carFieldsHandler = (e) => {

    this.setState({carsFields: {...this.state.carsFields, ...{[e.target.name]: e.target.value}}})
  }


  changeMakerOption = async makerOption => {
    const modelsData = await CarAPI.GetModelData(makerOption.id);
    const modelsSelectOptions = modelsData.data.data.map((item) => {
      return {value: item.title, label: item.title, id: item.id}
    })

    this.setState({
      carsFields: {...this.state.carsFields, ...{makerOption}},
      modelsSelectOptions
    });

  };

  changeModelOption = modelOption => {
    this.setState({carsFields: {...this.state.carsFields, ...{modelOption}}});
  };

  handlePickupDate = (date) => {
    this.setState({pick_up_date: date});
  }

  handleDeliveryDate = (date) => {
    this.setState({delivery_date: date});
  }

  render() {

    const {
      // cars,
      loadData = {},
      pick_up_date,
      delivery_date,
      myDriver,
      selectedDriver,
    } = this.state;

    const {origin_data = {}, destination_data = {}, client_data = {}} = loadData
    // const pickUpDate = (loadData.pick_up_date + '').split(' ')
    // const pickUpDateDivided = pickUpDate[0].split('-')
    // const deliveryDate = (loadData.delivery_date + '').split(' ')
    // const deliveryDateDivided = deliveryDate[0].split('-')
    const loadStatus = LoadAPI.CalculateLoadStatus(loadData);

    const trailerType = getEncloseValue(loadData.is_enclosed);
    const factoringSupported = loadData.factoring_supported || (client_data.name && !client_data.name.toLowerCase().includes("amazon"))

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={'loads'}/>
            <CustomTopBar/>
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">

                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">Load</div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to='/dashboard'>Home</Link></li>
                      <li className="breadcrumb-item"><Link to='/loads'>Loads</Link></li>
                      <li className="breadcrumb-item active">View Load</li>
                    </ol>
                  </div>
                </div>

                <div className="dashboard-content">

                  <div className="load">
                    <div className="load-back">
                      <Link to='/loads'><i className="fas fa-arrow-left"/> Back</Link>
                    </div>

                    <AvForm onSubmit={this.submitLoad}>

                      <div className="row">
                        <div className="col-12">
                          <AvField
                            name="loadNumber"
                            label="Load number"
                            required
                            errorMessage="Invalid load number"
                            type="text"
                            disabled={true}
                            value={loadData.load_id}
                            maxLength="100"
                            validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Pick up date</label>
                            <DatePicker
                              className="form-control"
                              selected={pick_up_date || ''}
                              onChange={this.handlePickupDate}
                              dateFormat="yyyyy-MM-dd HH:mm:ss"
                              minDate={new Date()}
                              disabled={true}
                              placeholderText={convertUTCToLocal(loadData.pick_up_date)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Delivery date</label>
                            <DatePicker
                              className="form-control"
                              selected={delivery_date || ''}
                              onChange={this.handleDeliveryDate}
                              dateFormat="yyyyy-MM-dd HH:mm:ss"
                              disabled={true}
                              minDate={new Date()}
                              placeholderText={convertUTCToLocal(loadData.delivery_date)}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Status</label>
                            <div style={{ width: 90 }}>
                              {/*{!loadData.origin_sign_date && (<div className="people-table-status-active">New</div>)}*/}
                              {/*{!!loadData.driver_id && !!loadData.origin_sign_date && !loadData.destination_sign_date  && (<div className="people-table-status-alert">Assigned</div>)}*/}
                              {/*{!!loadData.origin_sign_date && !!loadData.destination_sign_date && (<div className="people-table-status-picked">Picked</div>)}*/}

                              {loadStatus === "new" && (<div className="people-table-status-active">New</div>)}
                              {loadStatus === "assigned" && (<div className="people-table-status-alert">Assigned</div>)}
                              {loadStatus === "picked" && (<div className="people-table-status-picked">Picked</div>)}
                              {loadStatus === "dispatched" && (<div className="people-table-status-dispatched">Dispatched</div>)}
                              {loadStatus === "outdated" && (<div className="people-table-status-outdated">Outdated</div>)}
                              {loadStatus === "unknown" && (<div className="people-table-status-unknown">Unknown</div>)}

                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField type="select" name="select" label='Driver' disabled={true}>
                            <option>{myDriver} </option>
                          </AvField>
                        </div>

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Driver phone</label>
                            <input
                              className="form-control"
                              type="text"
                              maxLength="100"
                              readOnly={true}
                              value={selectedDriver ? JSON.parse(selectedDriver).phone : ''}
                            />
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="driverPrice"
                            label="Price"
                            required
                            errorMessage="Invalid price"
                            maxLength="100"
                            value={loadData.hauling}
                            disabled={true}
                            type="number"/>
                        </div>

                        {/* <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Cars to transport</label>
                            <div className="load-new-model">
                              <div className="load-new-model-header">
                                {cars.map((item, i) => {
                                  return (
                                    <div
                                      key={uuidv4()}
                                      className="load-new-model-header-item load-new-model-header-item-view">
                                      {item.car_maker_name}
                                    </div>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="">Trailer Type</label>
                            <div className="custom-radio">
                              <input
                                type="radio"
                                className="custom-control-radio"
                                name="trailerType"
                                value="Dry van"
                                disabled={true}
                                checked={trailerType === 'Dry van'}
                                onChange={() => this.setState({ trailerType: 'Dry van', enclose: 10 })}
                                id='trailer-type-dry-van'
                              />
                              <label className="custom-control-radio-label" htmlFor='trailer-type-dry-van'>
                                Dry van
                              </label>
                              <input
                                type="radio"
                                className="custom-control-radio"
                                name="trailerType"
                                value="Reefer"
                                disabled={true}
                                checked={trailerType === 'Reefer'}
                                onChange={() => this.setState({ trailerType: 'Reefer', enclose: 20})}
                                id='trailer-type-reefer'
                              />
                              <label className="custom-control-radio-label" htmlFor='trailer-type-reefer'>
                                Reefer
                              </label>
                              <input
                                type="radio"
                                className="custom-control-radio"
                                name="trailerType"
                                value="Flatbed"
                                disabled={true}
                                checked={trailerType === 'Flatbed'}
                                onChange={() => this.setState({ trailerType: 'Flatbed', enclose: 0 })}
                                id='trailer-type-flat-bed'
                              />
                              <label className="custom-control-radio-label" htmlFor='trailer-type-flat-bed'>
                                Flatbed
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="col-12">
                          <hr/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="client"
                            label="Broker"
                            errorMessage="Invalid broker"
                            value={client_data.name}
                            maxLength="100"
                            disabled={true}
                            type="text" validate={{required: {value: false}}}/>
                        </div>

                        <div className="col-12">
                          <div className="form-group custom-form-group">

                            <label htmlFor=""/>
                            <AvField
                              name="clientAddress"
                              errorMessage="Invalid broker address"
                              value={client_data.address}
                              maxLength="100"
                              disabled={true}
                              type="text" validate={{required: {value: false}}}/>
                            <AvField
                              name="clientCity"
                              errorMessage="Invalid broker city"
                              maxLength="100"
                              placeholder="city"
                              type="text"
                              disabled={true}
                              value={client_data.city}
                              validate={{required: {value: false}}}/>
                            <AvField
                              name="clientState"
                              errorMessage="Invalid broker state"
                              maxLength="100"
                              placeholder="state"
                              type="text"
                              disabled={true}
                              value={client_data.state}
                              validate={{required: {value: false}}}/>
                            <AvField
                              name="clientZip"
                              errorMessage="Invalid broker zip"
                              maxLength="100"
                              placeholder="ZIP"
                              type="number"
                              disabled={true}
                              value={client_data.zip}
                              validate={{required: {value: false}}}/>
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="clientEmail"
                            label="Broker email"
                            errorMessage="Invalid broker email"
                            type="text"
                            value={client_data.email}
                            maxLength="100"
                            disabled={true}
                            validate={{required: {value: false}}}/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="clientPhone"
                            label="Broker phone number"
                            errorMessage="Invalid broker phone"
                            type="_tel"
                            value={client_data.phone}
                            maxLength="100"
                            disabled={true}
                            validate={{required: {value: false}}}/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="note"
                            label="Note"
                            required
                            errorMessage="Invalid broker note"
                            value={loadData.note}
                            disabled={true}
                            type="textarea" validate={{required: {value: false}}}
                            maxLength={"3777"}
                            rows="6"
                            />
                            
                        </div>

                        <div className="col-12">
                          <hr/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="origin"
                            label="Origin"
                            required
                            errorMessage="Invalid origin"
                            value={origin_data.name}
                            maxLength="100"
                            disabled={true}
                            type="text" validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <div className="form-group custom-form-group">

                            <label htmlFor=""/>
                            <AvField
                              name="originAddress"
                              required
                              errorMessage="Invalid origin address"
                              value={origin_data.address}
                              maxLength="100"
                              disabled={true}
                              type="text" validate={{required: {value: true}}}/>
                            <AvField
                              name="originCity"
                              required
                              errorMessage="Invalid origin city"
                              maxLength="100"
                              placeholder="city"
                              type="text"
                              disabled={true}
                              value={origin_data.city}
                              validate={{required: {value: true}}}/>
                            <AvField
                              name="originState"
                              required
                              errorMessage="Invalid origin state"
                              maxLength="100"
                              placeholder="state"
                              type="text"
                              disabled={true}
                              value={origin_data.state}
                              validate={{required: {value: true}}}/>
                            <AvField
                              name="originZip"
                              required
                              errorMessage="Invalid origin zip"
                              maxLength="100"
                              placeholder="ZIP"
                              type="number"
                              disabled={true}
                              value={origin_data.zip}
                              validate={{required: {value: true}}}/>
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="originEmail"
                            label="Origin email"
                            required
                            errorMessage="Invalid origin"
                            type="text"
                            value={origin_data.email}
                            maxLength="100"
                            disabled={true}
                            validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="originPhone"
                            label="Origin phone number"
                            required
                            errorMessage="Invalid phone"
                            type="_tel"
                            value={origin_data.phone}
                            maxLength="100"
                            disabled={true}
                            validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="note_dispatch"
                            label="Dispatch Instructions"
                            required
                            errorMessage="Invalid dispatch instructions"
                            value={loadData.note_dispatch}
                            disabled={true}
                            type="textarea" 
                            validate={{required: {value: false}}}
                            rows="6"
                            maxLength={"3777"}
                          />
                        </div>

                        <div className="col-12">
                          <hr/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="destination"
                            label="Destination"
                            required
                            errorMessage="Invalid destination"
                            type="text"
                            value={destination_data.name}
                            maxLength="100"
                            disabled={true}
                            validate={{required: {value: true}}}/>
                        </div>

                        <div className="col-12">
                          <div className="form-group custom-form-group">
                            <label htmlFor=""/>
                            <AvField
                              name="destinationAddress"
                              required
                              errorMessage="Invalid destination address"
                              value={destination_data.address}
                              maxLength="100"
                              disabled={true}
                              type="text"/>
                            <AvField
                              name="destinationCity"
                              required
                              errorMessage="Invalid destination city"
                              maxLength="100"
                              placeholder="city"
                              type="text"
                              value={destination_data.city}
                              disabled={true}
                              validate={{required: {value: true}}}/>
                            <AvField
                              name="destinationState"
                              required
                              errorMessage="Invalid destination state"
                              maxLength="100"
                              placeholder="state"
                              type="text"
                              value={destination_data.state}
                              disabled={true}
                              validate={{required: {value: true}}}/>
                            <AvField
                              name="destinationZip"
                              required
                              errorMessage="Invalid destination zip"
                              maxLength="100"
                              placeholder="ZIP"
                              type="number"
                              value={destination_data.zip}
                              disabled={true}
                              validate={{required: {value: true}}}/>
                          </div>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="destinationEmail"
                            label="Destination Email"
                            required
                            errorMessage="Invalid destination email"
                            value={destination_data.email}
                            maxLength="100"
                            disabled={true}
                            type="text"/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="destinationPhone"
                            label="Destination phone number"
                            required
                            errorMessage="Invalid destination email"
                            value={destination_data.phone}
                            maxLength="100"
                            disabled={true}
                            type="text"/>
                        </div>

                        <div className="col-12">
                          <AvField
                            name="note_release"
                            label="Delivery Instructions"
                            required
                            errorMessage="Invalid delivery instructions"
                            value={loadData.note_release}
                            disabled={true}
                            type="textarea" 
                            validate={{required: {value: false}}}
                            maxLength={"3777"}
                            rows="6"
                          />
                        </div>

                        <div className="col-12">
                          <hr/>
                        </div>

                        {/* <div className="col-12">
                          <AvField
                            type="select"
                            name="select"
                            label='Payment Term'
                            className="load-select"
                            value={loadData.is_cod ? loadData.is_cod.toString() : ''}
                            disabled={true}
                            onChange={(e) => this.setState({selectedBillingType: e.target.value})}>
                            <option value="1">COD</option>
                            <option value="3">COP</option>
                            <option value="7">Quick Pay</option>
                            <option value="9">Zelle</option>
                            <option value="11">Venmo</option>
                            <option value="13">Cashapp</option>
                            <option value="5">Billing</option>
                          </AvField>
                        </div> */}


                        <div className="col-12">
                          <AvField
                            type="select"
                            name="selectFactoring"
                            label='Factoring'
                            className="load-select"
                            disabled={true}
                            // value={loadData.is_cod || !factoringSupported || `10`}
                            value={factoringSupported ? loadData.is_cod || '' : `0`}
                            validate={{required: {value: factoringSupported}}}
                            errorMessage="Invalid factoring selection"
                            onChange={(e) => this.setState({selectedBillingType: e.target.value})}>
                            <option value="">None</option>
                            <option value="10">RTS Financial</option>
                            <option value="20">Apex</option>
                            <option value="30">Truckstop</option>
                            <option value="40">Corefund</option>
                            <option value="50">Triumph</option>
                          </AvField>
                        </div>


                      </div>
                    </AvForm>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </main>
      </AUX>
    );
  }
}

const mapStatetoProps = state => {
  return {};
}

const mapDispatchtoProps = dispatch => {
  return {};
}

export default connect(mapStatetoProps, mapDispatchtoProps)(Load);
