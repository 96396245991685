import React, { Component } from "react";
import Select from "react-select";
import AUX from "../../../hoc/Aux_";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Map from "../Map";
import LoadsAPI from "../../../api/loadsAPI";
import downloadCsv from "../../../utils/ExportCSV";
import CustomSelect from "../CustomSelect/CustomSelect";
import TransportersAPI from "../../../api/transportersAPI";
import { sortByPriority } from "../../../helpers/sortByPriority";
import CustomTopBar from "../../../components/Layout/CustomTopBar";
import CustomSideBar from "../../../components/Layout/CustomSideBar";
import "./style.css";
import OpportunitiesAPI from "../../../api/opportunitiesAPI";
import DocumentsApi from "../../../api/documentsApi";
import uuidv4 from "uuid";
import { months } from '../../../helpers/DateUtils';
import { googleSearch, googleMapSearch, phoneCall, mailTo, openOnlineBol } from '../../../helpers/LinkUtils';
import formatAddress from "../../../utils/AddressParser";
import { convertUTCToLocal } from "../../../helpers/DateUtils";

class RowItem extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      carData: [],
      priorityMode: null,
      priorityText: '',
      commentMode: null,
      commentText: '',
      instructionDispatchMode: null,
      instructionDispatchText: '',
      instructionDeliveryMode: null,
      instructionDeliveryText: '',
      priceMode: null,
      priceText: '',
      load: props.item,
      subscriptionStatus: '',
    }
  }

  async toggleRow(e) {
    const data = await LoadsAPI.GetLoadsCars(this.props.item.id)
    const resp = data.data.data
    let carDataText = []
    resp.forEach(el => {
      carDataText.push(el);
    })
    this.setState({
      open: !this.state.open,
      carData: carDataText
    })
  }

  deleteComment = async (id) => {
    const res = await LoadsAPI.SaveLoadNote({ id, note: '' })
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoad(id)
      this.setState({ commentMode: null, load: data.data.data })
    } else {
      this.setState({ commentMode: null })
    }
  }

  handleComment = async (id) => {
    const { commentText } = this.state
    const res = await LoadsAPI.SaveLoadNote({ id, note: commentText })
    if (res && res.status === 200) {
      const data = await LoadsAPI.GetLoad(id)
      this.setState({ commentMode: null, load: data.data.data })
    } else {
      this.setState({ commentMode: null })
    }
  }
  
  deleteInstructionDispatch = async (id) => {
    const res = await LoadsAPI.SaveLoadNote({ id, note: '', type: 'dispatch' })
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoad(id)
      this.setState({ instructionDispatchMode: null, load: data.data.data })
    } else {
      this.setState({ instructionDispatchMode: null })
    }
  }

  handleInstructionDispatch = async (id) => {
    const { instructionDispatchText } = this.state
    const res = await LoadsAPI.SaveLoadNote({ id, note: instructionDispatchText, type: 'dispatch' })
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoad(id)
      this.setState({ instructionDispatchMode: null, load: data.data.data })
    } else {
      this.setState({ instructionDispatchMode: null })
    }
  }

  deleteInstructionDelivery = async (id) => {
    const res = await LoadsAPI.SaveLoadNote({ id, note: '', type: 'release' })
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoad(id)
      this.setState({ instructionDeliveryMode: null, load: data.data.data })
    } else {
      this.setState({ instructionDeliveryMode: null })
    }
  }

  handleInstructionDelivery = async (id) => {
    const { instructionDeliveryText } = this.state
    const res = await LoadsAPI.SaveLoadNote({ id, note: instructionDeliveryText, type: 'release' })
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoad(id)
      this.setState({ instructionDeliveryMode: null, load: data.data.data })
    } else {
      this.setState({ instructionDeliveryMode: null })
    }
  }

  render() {
    const {
      priorityMode,
      priorityText,
      commentMode,
      commentText,
      instructionDispatchMode,
      instructionDispatchText,
      instructionDeliveryMode,
      instructionDeliveryText,
      load: item
    } = this.state

    const isOpen = this.state.open
    
    const pickUpDate = (convertUTCToLocal(item.pick_up_date + "")).split(
      " "
    );
    const pickUpDateDivided = pickUpDate[0].split("-");
    const deliveryDate = (
      convertUTCToLocal(item.delivery_date + "")
    ).split(" ");
    const deliveryDateDivided = deliveryDate[0].split(
      "-"
    );

    const [origin_month, origin_day] = [(pickUpDateDivided[1] || '-'), (pickUpDateDivided[2] || '-')];
    const origin_date = pickUpDateDivided[0] !== 'null' || pickUpDateDivided[0] ? [months[origin_month], origin_day].join('-') + ' ' + pickUpDate[1] : '-';

    const [destination_month, destination_day] = [(deliveryDateDivided[1] || '-'), (deliveryDateDivided[2] || '-')];
    const destination_date = deliveryDateDivided[0] !== 'null' || deliveryDateDivided[0] ? [months[destination_month], destination_day].join('-') + ' ' + deliveryDate[1] : '-';


    const userID = JSON.parse(localStorage.getItem("userInfo")).id;
    const isDealerLoad = item.user_id !== userID;
    const loadStatus = LoadsAPI.CalculateLoadStatus(item);

    if (item.origin_data === null) {
      item.destination_data = { address: '', name: '', phone: '', email: '' };
    }

    const originTitle = formatAddress(item.origin_data.address);
    const originName = item.origin_data.name || '-';
    const originAddress = item.origin_data.address;
    const originPhone = item.origin_data.phone;
    const originEmail = item.origin_data.email;

    if (item.destination_data === null) {
      item.destination_data = { address: '', name: '', phone: '', email: '' };
    }

    const destinationTitle = formatAddress(item.destination_data.address);
    const destinationName = item.destination_data.name || '-';
    const destinationAddress = item.destination_data.address;
    const destinationPhone = item.destination_data.phone;
    const destinationEmail = item.destination_data.email;

    const clientPresent = item.client_data ? true : false;
    const clientName = clientPresent ? item.client_data.name || '-' : '-';
    const clientAddress = clientPresent ? item.client_data.address : '';
    const clientPhone = clientPresent ? item.client_data.phone : '';
    const clientEmail = clientPresent ? item.client_data.email : '';

    return (
      <tr className={'tr-additional-info tr-first'}>
        <td colSpan={11} className={'td-no-padding'}>
          <table className={'table-no-padding'}>
            <tbody>
                <tr className={'tr-additional-info'}>
                  <td onClick={this.toggleRow.bind(this)} className={'td-is-open-load'}>
                    <img src={'/assets/images/dropdown2.png'} alt='arrow' className={this.state.open ? 'dropdown fa-rotate-90' : 'dropdown fa-rotate-0'} />
                  </td>
                  <td><strong>{item.load_id || "-"}</strong></td>
                  <td>
                    {!isDealerLoad && (
                      <div className="people-table-comment">
                        {priorityMode === item.id ? (
                          <div className="people-table-comment-form">
                            <input
                              type="text"
                              className="form-control"
                              maxLength="4"
                              onChange={e =>
                                this.setState({
                                  priorityText: e.target.value
                                })
                              }
                              value={
                                priorityMode
                                  ? priorityText
                                  : item.priority
                              }
                            />
                            <p
                              onClick={() =>
                                this.props.onHandlePriority(item.id, this.state.priorityText)
                              }
                            >
                              {"Save"}
                            </p>
                            <span
                              onClick={() =>
                                this.setState({
                                  priorityMode: null
                                })
                              }
                            >
                              Cancel
                            </span>
                          </div>
                        ) : (
                          <div className="people-table-comment-control">
                            <div
                              className={
                              item.priority === 1
                                ? "people-table-comment-text people-table-comment-text-red"
                                : "people-table-comment-text"
                              }
                            >
                              {item.priority || ""}
                            </div>
                            {item.priority ? (
                              <div className="people-table-comment-form-btns">
                                <p
                                  onClick={() =>
                                    this.setState({
                                      priorityMode: item.id,
                                      priorityText: item.priority
                                    })
                                  }
                                >
                                  {"Edit"}
                                </p>
                                <span
                                  onClick={() =>
                                    this.props.onDeletePriority(item.id)
                                  }
                                >
                                  Delete
                                </span>
                              </div>
                            ) : (
                              <p
                                onClick={() =>
                                  this.setState({
                                    priorityMode: item.id,
                                    priorityText: item.priority
                                  })
                                }
                              >
                                {"Add"}
                              </p>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </td>
                  <td>
                    <em>{`${origin_date}`}</em>
                  </td>
                  <td
                    className={item.delayed ? "data-warning" : ""}
                  >
                    <em>{`${destination_date}`}</em>
                  </td>
                  <td>
                    {loadStatus === "dispatched" && (
                      <div className="people-table-status-dispatched">
                        Dispatched
                      </div>
                    )}
                    {loadStatus === "new" && (
                      <div className="people-table-status-active">
                        New
                      </div>
                    )}
                    {loadStatus === "outdated" && (
                      <div className="people-table-status-outdated">
                        Outdated
                      </div>
                    )}
                    {loadStatus === "assigned" && (
                      <div className="people-table-status-alert">
                        Assigned
                      </div>
                      )}
                    {loadStatus === "picked" && (
                      <div className="people-table-status-picked">
                        Picked
                      </div>
                    )}
                    {loadStatus === "unknown" && (
                      <div className="people-table-status-unknown">
                        Unknown
                      </div>
                    )}
                  </td>
                  <td>
                    <CustomSelect
                      drivers={this.props.drivers}
                      loadId={item.id}
                      setLoading={this.props.setLoading}
                      disable={
                        (item.driver_id &&
                          !item.origin_sign_date) ||
                        item.origin_sign_date
                      }
                      getLoads={this.props.getLoads}
                    >
                      {item.driver_data && `${item.driver_data.first_name} ${item.driver_data.last_name}`}
                    </CustomSelect>
                  </td>
                  <td><strong>{"$" + item.hauling}</strong></td>
                  <td><a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(originName + ', ' + originAddress)}`} target="_blank" rel="noopener noreferrer">{originTitle}</a></td>
                  <td><a href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(destinationName + ', ' + destinationAddress)}`} target="_blank" rel="noopener noreferrer">{destinationTitle}</a></td>
                  <td>
                    <div className="people-table-btn-group">
                      <Link
                        to={`/load/${item.id}/view`}
                        className="people-table-btn-group-icon fas fa-eye"
                        title="View"
                      />
                      {item.origin_data.creator_user_id !== userID ? 
                      (
                        <div
                          className="people-table-btn-group-icon far fa-edit people-table-btn-edit-lock"
                          title="Edit"
                        />
                      ) : (
                        <Link
                          to={`/load/${item.id}`}
                          className="people-table-btn-group-icon far fa-edit"
                          title="Edit"
                        />
                      )}
                      <div
                          className={ item.driver_data && !item.driver_id ? "people-table-btn-group-icon fas fa-user-minus" : "people-table-btn-group-icon fas fa-trash" }
                          title="Delete"
                          onClick={() => this.props.openPopup(item.id, item.driver_data && !item.driver_id, item.load_id)}
                        />
                    </div>
                  </td>
                </tr>
                <tr className={isOpen ? 'openTrRow': 'hiddenTrRow'}>
                  <td className={'td-no-padding ' + isOpen ? 'openTdRow': 'hiddenTdRow'} colSpan={11}>
                    <div className={isOpen ? 'openRow': 'hiddenRow'}>
                          <div className={'carRow'}>
                            <span className="car_detail_box">
                            {this.state.carData.map((car) => {
                              return (
                                <span className="load_details_header" onClick={() => googleSearch((car.year || '') + " " + (car.car_maker_name || '') + " " + (car.car_model_name || '') + " " + (car.car_type ? car.car_type : ''))}>
                                  <strong>{(car.year || '')} {(car.car_maker_name || '')} {(car.car_model_name || '')}</strong> <span className="rowTitle">{(car.car_type ? ('Type: ' + car.car_type) : '')}</span> <span className="rowTitle">{(car.vin ? ('VIN: ' + car.vin) : '')}</span>
                                </span>
                              )
                            })}
                            {/* {item.origin_sign_date && item.origin_sign_date !== null && item.origin_sign_date !== '' ? 
                              <span className="load_details-btn" onClick={e => openOnlineBol(item.id)}>Online BOL</span> : ''
                            } */}
                            </span>
                            <div className="rowContainer">
                            <div className="rowOuter" style={{ width: '33%' }}>
                              <ul>
                                <li className="rowSubtitle">ORIGIN</li>
                                <li onClick={() => googleSearch(originName)}><strong>{originName}</strong></li>
                                <li onClick={() => googleMapSearch(originAddress)}>{originAddress}</li>
                                <li onClick={() => phoneCall(originPhone)}>{originPhone ? 'Tel. ' + originPhone : ''}</li>
                                <li onClick={() => mailTo(originEmail, originName)}>{originEmail ? 'Email: ' + originEmail : ''}</li>
                              </ul>
                              <br/>
                                <div className="people-table-comment">
                                <div className="rowSubtitle">DISPATCH INSTRUCTIONS</div>
                      {instructionDispatchMode === item.id ?
                        (<div className="people-table-comment-form">
                          <textarea
                            // type="textarea"
                            className="form-control"
                            cols="40"
                            // rows="6"
                            rows={Math.max(4, Math.ceil((instructionDispatchText ? instructionDispatchText.length : 0) / 40))}
                            maxLength={"3777"}
                            onChange={(e) => this.setState({instructionDispatchText: e.target.value})}
                            value={instructionDispatchMode ? instructionDispatchText : item.note_dispatch}
                          />
                          <p onClick={() => this.handleInstructionDispatch(item.id)}>Save</p>
                          <span onClick={() => this.setState({instructionDispatchMode: null})}>Cancel</span>
                        </div>)
                        : (
                          <div className='people-table-comment-control'>
                            <div className={'people-table-comment-text'}>{item.note_dispatch || ''}</div>
                            {item.note_dispatch ? (
                                <div className='people-table-comment-form-btns'>
                                  <p onClick={() => this.setState({instructionDispatchMode: item.id, instructionDispatchText: item.note_dispatch})}>
                                    {'Edit'}
                                  </p>
                                  <span onClick={() => this.deleteInstructionDispatch(item.id)}>{'Delete'}</span>
                                </div>
                              ) :
                              <p onClick={() => this.setState({instructionDispatchMode: item.id, instructionDispatchText: item.note_dispatch})}>
                                {'Add'}
                              </p>
                            }
                          </div>
                        )}
                    </div>
                            </div>
                            <div className="rowInner" style={{ width: '33%' }}>
                            <ul>
                                <li className="rowSubtitle">DESTINATION</li>
                                <li onClick={() => googleSearch(destinationName)}><strong>{destinationName}</strong></li>
                                <li onClick={() => googleMapSearch(destinationAddress)}>{destinationAddress}</li>
                                <li onClick={() => phoneCall(destinationPhone)}>{destinationPhone ? 'Tel. ' + destinationPhone : ''}</li>
                                <li onClick={() => mailTo(destinationEmail, destinationName)}>{destinationEmail ? 'Email: ' + destinationEmail : ''}</li>
                              </ul>
                              <br/>
                                <div className="people-table-comment">
                                <div className="rowSubtitle">DELIVERY INSTRUCTIONS</div>
                      {instructionDeliveryMode === item.id ?
                        (<div className="people-table-comment-form">
                          <textarea
                            // type="text"
                            className="form-control"
                            // maxLength="10"
                            cols="40"
                            // rows="6"
                            rows={Math.max(4, Math.ceil((instructionDeliveryText ? instructionDeliveryText.length : 0) / 40))}
                            maxLength={"3777"}
                            onChange={(e) => this.setState({instructionDeliveryText: e.target.value})}
                            value={instructionDeliveryMode ? instructionDeliveryText : item.note_release}
                          />
                          <p onClick={() => this.handleInstructionDelivery(item.id)}>Save</p>
                          <span onClick={() => this.setState({instructionDeliveryMode: null})}>Cancel</span>
                        </div>)
                        : (
                          <div className='people-table-comment-control'>
                            <div className={'people-table-comment-text'}>{item.note_release || ''}</div>
                            {item.note_release ? (
                                <div className='people-table-comment-form-btns'>
                                  <p onClick={() => this.setState({instructionDeliveryMode: item.id, instructionDeliveryText: item.note_release})}>
                                    {'Edit'}
                                  </p>
                                  <span onClick={() => this.deleteInstructionDelivery(item.id)}>{'Delete'}</span>
                                </div>
                              ) :
                              <p onClick={() => this.setState({instructionDeliveryMode: item.id, instructionDeliveryText: item.note_release})}>
                                {'Add'}
                              </p>
                            }
                          </div>
                        )}
                    </div>
                    </div>
                              <div className="rowLast" style={{ width: '33%' }}>
                                { clientPresent ?
                                <ul>
                                  <li className="rowSubtitle">BROKER</li>
                                  <li onClick={() => googleSearch(clientName)}><strong>{clientName}</strong></li>
                                  <li onClick={() => googleMapSearch(clientAddress)}>{clientAddress}</li>
                                  <li onClick={() => phoneCall(clientPhone)}>{clientPhone ? 'Tel. ' + clientPhone : ''}</li>
                                  <li onClick={() => mailTo(clientEmail, clientName)}>{clientEmail ? 'Email: ' + clientEmail : ''}</li>
                                </ul>
                                : null }
                                { clientPresent ? <br/> : null }
                                <div className="people-table-comment">
                                <div className="rowSubtitle">NOTE</div>
                      {commentMode === item.id ?
                        (<div className="people-table-comment-form">
                          <textarea
                            // type="text"
                            className="form-control"
                            // maxLength="10"
                            cols="40"
                            // rows="6"
                            rows={Math.max(4, Math.ceil((commentText ? commentText.length : 0) / 40))}
                            onChange={(e) => this.setState({commentText: e.target.value})}
                            value={commentMode ? commentText : item.note}
                          />
                          <p onClick={() => this.handleComment(item.id)}>Save</p>
                          <span onClick={() => this.setState({commentMode: null})}>Cancel</span>
                        </div>)
                        : (
                          <div className='people-table-comment-control'>
                            <div className={'people-table-comment-text'}>{item.note || ''}</div>
                            {item.note ? (
                                <div className='people-table-comment-form-btns'>
                                  <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                    {'Edit'}
                                  </p>
                                  <span onClick={() => this.deleteComment(item.id)}>{'Delete'}</span>
                                </div>
                              ) :
                              <p onClick={() => this.setState({commentMode: item.id, commentText: item.note})}>
                                {'Add'}
                              </p>
                            }
                          </div>
                        )}
                    </div>
                    </div>
                            </div>
                          </div>
                    </div>
                  </td>
                </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }
}

class Loads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loads: [],
      page: 1,
      initialPage: 0,
      limit: 10,
      total: 0,
      status: "active",
      popupOpen: false,
      popupItemId: "",
      popupHasDriver: false,
      popupLoadId: "",
      delayTimer: "",
      priorityMode: null,
      priorityText: "",
      commentMode: null,
      commentText: "",
      delayed: false,
      transporters: [],
      drivers: [],
      defaultSelectValue: { label: "All drivers", value: "" },
      transporterSelectedValue: {label: 'All drivers', value: ''},
      isLoading: false,
      files: [],
      subscriptionStatus: '',
    };
    this.setLoading = this.setLoading.bind(this);
    this.mapRef = React.createRef();
  }

  setStateLoads = data => {
    this.setState({ loads: data.data.data, total: data.data.data_total_count });
  };

  getLoads = async () => {
    const { page, limit, status } = this.state;
    const data = await LoadsAPI.GetLoadsData({ page, limit, status });
    this.setStateLoads(data);
  };

  getLoadsWithFilter = async page => {
    const { limit, status, delayed, driver_id } = this.state;
    const data = await LoadsAPI.GetLoadsData({
      page,
      limit,
      status,
      ...(delayed ? { delayed: true } : {}),
      ...(driver_id ? { driver_id } : {})
    });
    return data;
  };

  async componentDidMount() {
    this.getLoads();
    const data = await TransportersAPI.GetTransportersStatWithLoad();
    const { data: allDrivers } = await TransportersAPI.GetMyTransportersData();
    const {
      data: { files }
    } = await DocumentsApi.list();
    const transportersStatusFilter = data.data.data.filter(
      item => item.status !== "invited"
    );
    const transporters = transportersStatusFilter.map(item => {
      return { value: item.id, label: `${item.name}`, id: item.id };
    });
    const drivers = allDrivers.data.map(el => ({
      label: `${el.first_name} ${el.last_name}`,
      value: el.id
    }));
    transporters.unshift({ label: "All drivers", value: "" });
    this.setState({ transporters, drivers, files });
  }

  handlePageChange = async page => {
    const data = await this.getLoadsWithFilter(page.selected + 1);
    this.setState({
      page: page.selected + 1,
      loads: data.data.data,
      initialPage: page.selected
    });
  };

  searching = async value => {
    let { delayTimer, status } = this.state;
    const paginationSearch = {
      page: 1,
      limit: 10,
      search: value,
      status
    };

    clearTimeout(delayTimer);

    delayTimer = setTimeout(async () => {
      if (value.length >= 3) {
        const data = await LoadsAPI.SearchLoad(paginationSearch);
        this.setState({
          loads: data.data.data,
          total: data.data.data_total_count
        });
      } else {
        const data = await LoadsAPI.GetLoadsData({
          page: 1,
          limit: 10,
          status
        });
        this.setState({
          loads: data.data.data,
          total: data.data.data_total_count
        });
      }
    }, 1500);

    this.setState({ delayTimer });
  };

  openPopup = (itemId, hasDriver, loadId) => {
    this.setState({ popupOpen: true, popupItemId: itemId, popupHasDriver: hasDriver, popupLoadId: loadId});
  };

  handleDelayed = async e => {
    const {
      target: { checked }
    } = e;
    const { limit, status, driver_id } = this.state;
    const data = await LoadsAPI.GetLoadsData({
      page: 1,
      limit,
      status,
      ...(checked ? { delayed: checked } : {}),
      ...(driver_id ? { driver_id } : {})
    });
    this.setState({ delayed: !!checked, initialPage: 0 });
    this.setStateLoads(data);
  };

  onTransporterChange = async (data) => {
    if (data === null || data === '') {
      this.setState({transporterSelectedValue: {label: 'All drivers', value: ''}}, () => this.handleDriver(data));
    } else {
      this.setState({transporterSelectedValue: (data && data.id && isNaN(data.id)) ? '' : data}, () => this.handleDriver(data));
    }
  }

  handleDriver = async value => {
    const { limit, status, delayed } = this.state;
    const data = await LoadsAPI.GetLoadsData({
      page: 1,
      limit,
      status,
      ...(delayed ? { delayed: true } : {}),
      ...(value.id ? { driver_id: value.id } : {})
    });
    this.setState({ driver_id: value.id || null, initialPage: 0 }, () => this.mapRef.current.fetchData(value.id, value.id));
    this.setStateLoads(data);
  };

  deleteLoad = async () => {
    const { popupItemId, page, limit, status, driver_id } = this.state;
    const load = this.state.loads.find(item => item.id === popupItemId);

    if (load.driver_data && !load.driver_id) {
      const { data: offers } = await OpportunitiesAPI.getOffer(popupItemId);
      const offer = offers.find(item => item.driver_id);
      await LoadsAPI.DeleteInvite(offer.id);
    } else {
      await LoadsAPI.DeleteLoad(popupItemId);
      this.handleDriver({ id: driver_id });
    }

    const data = await LoadsAPI.GetLoadsData({ page, limit, status });

    this.setState({
      loads: data.data.data,
      total: data.data.data_total_count,
      popupOpen: false,
      popupItemId: "",
      popupHasDriver: false,
      popupLoadId: ""
    });
  };

  handlePriority = async (id, priorityText) => {
    const { limit, page, status } = this.state;

    if (!Number(priorityText)) {
      this.setState({
        priorityMode: null
      });
      return;
    }

    const res = await LoadsAPI.SaveLoadPriority({ id, priority: priorityText });
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoadsData({ page, limit, status });
      this.setState({
        priorityMode: null,
        loads: data.data.data,
        total: data.data.data_total_count
      });
    } else {
      this.setState({ priorityMode: null });
    }
  };

  deletePriority = async id => {
    const { limit, page, status } = this.state;

    const res = await LoadsAPI.SaveLoadPriority({ id, priority: 0 });
    if (res.status === 200) {
      const data = await LoadsAPI.GetLoadsData({ page, limit, status });
      this.setState({
        priorityMode: null,
        loads: data.data.data,
        total: data.data.data_total_count
      });
    } else {
      this.setState({ priorityMode: null });
    }
  };

  handleExportCSV = async () => {
    const { page, limit, status, delayed, driver_id } = this.state;
    const data = await LoadsAPI.GetLoadsData({
      page,
      limit,
      status,
      ...(delayed ? { delayed: true } : {}),
      ...(driver_id ? { driver_id } : {}),
      to_csv: true
    });
    downloadCsv(data.data, "exportedLoads");
  };

  setLoading = value => {
    this.setState({ isLoading: value });
  };

  render() {
    const {
      loads,
      limit,
      total,
      popupOpen,
      popupHasDriver,
      popupLoadId,
      transporters,
      drivers,
      defaultSelectValue,
      delayed,
      driver_id,
      // files,      
    } = this.state;

    return (
      <AUX>
        <main>
          <div id="wrapper">
            <CustomSideBar page={"loads"} />
            <CustomTopBar />
            <div className="content-page">
              <div className="content">
                <div className="main-content-header page-title-box">
                  <div className="main-content-header-left">
                    <div className="main-content-header-title page-title">
                      Dispatch
                    </div>
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/dashboard">Home</Link>
                      </li>
                      <li className="breadcrumb-item active">Dispatch</li>
                    </ol>
                    <div className="main-content-header-search">
                      <input
                        className="form-control"
                        type="search"
                        id="example-text-input"
                        placeholder="Search by load number, pick up date, delivery date, pick up address, origin address..."
                        maxLength="100"
                        onChange={e => this.searching(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="main-content-header-right">
                    <Link to="/load">
                      <button className="btn custom-btn main-content-header-btn">
                        Create Load
                      </button>
                    </Link>
                  </div>
                </div>

                <div className="dashboard-content">
                  <div className="people-table">
                    <div className="people-table-header">
                      <ul className="load-table-header header-first">
                        <li>
                          <div className="people-table-header-title">
                            Active Loads
                          </div>
                        </li>
                        <li>
                          <div className="people-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li>
                                <span className="custom-form-control-wrap">
                                  <Select
                                    className="custom-form-control-select-drivers _filters"
                                    defaultValue={defaultSelectValue}
                                    onChange={this.onTransporterChange}
                                    options={transporters}
                                    placeholder={"type transporter's name"}
                                    value={this.state.transporterSelectedValue}
                                    onFocus={(e) => {
                                      if (this.state.transporterSelectedValue.value === '') {
                                        this.setState({transporterSelectedValue: {label: '', value: ''}});
                                      }
                                    }}
                                    onBlur={(e) => {
                                      if (this.state.transporterSelectedValue.value === '') {
                                        this.setState({transporterSelectedValue: {label: 'All drivers', value: ''}});
                                      } else {
                                        this.setState({transporterSelectedValue: this.state.transporterSelectedValue}, () => this.handleDriver(this.state.transporterSelectedValue));
                                      }
                                    }}
                                  />
                                </span>
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li>
                          <div className="people-table-header-filter">
                            <ul className="load-table-header header-second">
                              <li>
                                <input
                                  className={"filter_checkbox"}
                                  defaultChecked={delayed}
                                  ref="delayed"
                                  type={"checkbox"}
                                  onChange={this.handleDelayed}
                                />{" "}
                                show delayed loads only
                              </li>
                            </ul>
                          </div>
                        </li>
                        <li className={"exportCSV"}>
                          <div
                            className="img-container"
                            type="submit"
                            onClick={this.handleExportCSV}
                          >
                            <span className="export-list-text">
                              Export list
                            </span>
                            <img
                              className={"export-csv-image"}
                              src="assets/images/exportCSV.png"
                              alt="Download CSV"
                            />
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="people-table-header-search">{total} search results</div>
                    <table>
                      <thead>
                        <tr>
                          <th>{""}</th>
                          <th>Load number</th>
                          <th>Priority</th>
                          <th>Pick up date</th>
                          <th>Delivery date</th>
                          <th style={{ textAlign: 'center' }}>Status</th>
                          <th>Driver</th>
                          <th>Price</th>
                          <th>Origin</th>
                          <th>Destination</th>
                          <th>{""}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!this.state.isLoading &&
                          sortByPriority(loads).map((item, i) => {
                            return (
                              <RowItem 
                              key={uuidv4()} 
                              onDeletePriority={this.deletePriority} 
                              onHandlePriority={this.handlePriority} 
                              item={item} 
                              drivers={drivers} 
                              openPopup={this.openPopup} 
                              getLoads={this.getLoads}
                              setLoading={this.setLoading}
                              />
                            )
                        })
                      }
                      </tbody>
                    </table>

                    <ReactPaginate
                      forceSelected={this.state.initialPage}
                      forcePage={this.state.initialPage}
                      previousLabel={"previous"}
                      nextLabel={"next"}
                      breakLabel={"..."}
                      marginPagesDisplayed={2}
                      containerClassName={"pagination custom-pagination"}
                      subContainerClassName={"pages pagination "}
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                      breakClassName={"page-item disabled"}
                      breakLinkClassName={"page-link disabled"}
                      onPageChange={this.handlePageChange}
                      pageCount={Math.ceil(total / limit)}
                    />
                  </div>

                  <div className="loads-map">
                    <div className="loads-map-header">
                      <div className="loads-map-title">Map</div>
                    </div>
                    <Map ref={this.mapRef} driverId={driver_id} delayed={delayed} showOffers={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {popupOpen ? (
            <div
              className="modal fade bs-example-modal-lg custom-modal show "
              tabIndex="-1"
              role="dialog"
              aria-labelledby="myLargeModalLabel"
              style={{
                paddingRight: "15px",
                display: "block",
                background: "rgba(0,0,0,0.6)"
              }}
            >
              <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                      { popupHasDriver ? "Edit Load" : "Delete Load"} - { popupLoadId }
                    </h5>
                    <button
                      type="button"
                      className="close"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      ×
                    </button>
                  </div>
                  <div className="modal-body modal-body-delete">
                    <p className="modal-body-delete-text">
                    { popupHasDriver ? "This will remove driver from the load." : "This will delete the load."}
                      {/* This will remove driver from the load or completely delete
                      the load if status is NEW. <br />
                      Proceed? */}
                      <br /> Proceed?
                    </p>
                    <Button
                      className="btn btn-primary w-md custom-btn"
                      onClick={this.deleteLoad}
                    >
                      Yes
                    </Button>
                    <Button
                      className="btn w-md"
                      onClick={() => this.setState({ popupOpen: false })}
                    >
                      No
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </main>
      </AUX>
    );
  }
}

export default Loads;
